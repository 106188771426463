













































































































































































import { get } from 'lodash'
import { Component, Vue } from 'vue-property-decorator'
import VsImage from '@/modules/landingpages/components/VsImage/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import VsSidebarLayout from '@/components/VsSidebarLayout/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import { getEntityLabels } from '@/api/consoleApi/entityLabels'
import VsDragoModal from '@/modules/campaigns/components/VsDragoModal/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import VsPreviewModal from '@/modules/campaigns/components/VsPreviewModal/Index.vue'
import { UserModule } from '@/store/modules/user'
import html2canvas from 'html2canvas'
import { uploadTemplateImage, deleteThemeById, getTemplateById, duplicateTemplate, exportAsHtml, templatePreview, editTemplate } from '@/api/consoleApi/contents/templates'

@Component({
    name: 'TemplateEdit',
    components: {
        VsImage,
        VsConfirm,
        VsContainer,
        VsSectionHeader,
        VsSidebarLayout,
        VsDropdownButton,
        VsDragoModal,
        VsPreviewModal,
    },
})
export default class extends Vue {
    private template: any = null
    private loading = false
    private entityLabels: any[] = []
    htmlPreview = ''

    $refs!: any

    get templateId (): any {
        return get(this.$route, 'params.templateId', '')
    }

    get user () {
        return UserModule.user
    }

    get previewImage () {
        return this.template.thumbnail_url ? this.template.thumbnail_url + `?t=${Date.now()}` : ''
    }

    get canExportHtml () {
        return this.user?.configuration?.rules.exportHtml
    }

    get isManagerRoute () {
        return this.$route.name === 'managerTemplateEdit'
    }

    async beforeMount () {
        this.getTheme()
        this.getEntityLabels()
    }

    async contentSaved () {
        this.loading = true
        await this.getTheme()
        await this.getPreview()
    }

    async getTheme () {
        try {
            const resp = await getTemplateById(this.templateId, { with: 'entityLabels' })
            this.template = resp.data.data
            if (this.$route.query?.openDrago) {
                this.$router.replace({
                    query: undefined,
                })
                this.openDrago(resp.data.data)
            }
        } catch (e) {
            console.log(e)
        }
    }

    savePageName () {
        return ''
    }

    openDrago (template: any) {
        if (!template) return
        this.$refs.vsDragoModal.openModal(template, [])
    }

    private async getScreenshot (e: any) {
        const iframeEl: any = e.target
        if (!iframeEl) return
        const body = iframeEl.contentWindow.document.getElementsByTagName('BODY')[0]

        const canvas = await html2canvas(body, {
            windowWidth: 700,
            width: 700,
            height: 800,
            proxy: '/app/userapi/users/screenshot-images-proxy',
        })
        try {
            let base64 = canvas.toDataURL('image/jpeg', 0.1)
            base64 = await this.processImage(base64)
            const resp = await uploadTemplateImage(
                this.templateId,
                {
                    base64_image: base64,
                },
            )
            this.template.thumbnail_url = ''
            this.template.thumbnail_url = resp.data.data.url
            await this.updateThemeById()
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante il salvataggio dell\'anteprima',
                timeout: 3000,
                aspect: VsToastAspectEnum.warning,
            })
            console.log(e)
        }
        this.htmlPreview = ''
        this.loading = false
    }

    private async getPreview () {
        try {
            const response = await templatePreview(this.templateId)
            this.htmlPreview = response.data.replace('<head>', '<head><style>body{overflow:hidden;}</style>')
        } catch (e) {
            console.log(e)
        }
    }

    async deleteThemeById () {
        try {
            await this.$refs.deleteTemplateConfirm.openConfirm()
        } catch (e) {
            return
        }
        this.loading = true
        try {
            deleteThemeById(this.templateId)
            this.$root.$vsToast({
                heading: 'Modello eliminato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.$router.replace({
                name: this.isManagerRoute ? 'managerEmailEditorIndex' : 'emailEditorIndex',
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la cancellazione del modello',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    async duplicateTheme () {
        if (!this.templateId) return
        this.loading = true
        try {
            const data = {
                name: 'Copia di ' + this.template.title,
            }
            await duplicateTemplate(this.templateId, data)
            this.$root.$vsToast({
                heading: 'Modello duplicato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la duplicazione del modello',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    async exportAsHtml () {
        if (!this.templateId) return
        this.loading = true
        try {
            const { data } = await exportAsHtml(this.templateId)
            const url = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `template-${this.templateId}.html`)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante l\'esportazione del modello',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    async updateThemeById () {
        const valid = await this.$refs.templateData.validate()
        if (!this.templateId || !valid) return
        this.loading = true
        try {
            await editTemplate(this.templateId, {
                ...this.template,
                entity_labels: this.template.entity_labels.map((el: any) => el.id),
            })
            this.$root.$vsToast({
                heading: 'Modello salvato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante il salvataggio del modello',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    private openPreview () {
        this.$refs.previewModal.openModalNew({
            ...this.template,
            id: this.templateId,
        }, 'emailTemplate')
    }

    private async getEntityLabels () {
        this.loading = true
        try {
            const resp = await getEntityLabels()
            this.entityLabels = resp.data.data
        } catch (e) {
        }
        this.loading = false
    }

    private replaceTags (tagIds: string[]) {
        const entityLabels = this.entityLabels.filter(el => tagIds.includes(el.id))
        this.template.entity_labels = entityLabels
    }

    async processImage (base64: string, min_image_size = 20) {
        if (base64) {
            const old_size = this.calcImageSize(base64)
            if (old_size > min_image_size) {
                const resized = await this.reduceImageFileSize(base64)
                return resized
            } else {
                return base64
            }
        } else {
            return base64
        }
    }

    calcImageSize (base64: string) {
        let y = 1
        if (base64.endsWith('==')) {
            y = 2
        }
        const x_size = (base64.length * (3 / 4)) - y
        return Math.round(x_size / 1024)
    }

    async reduceImageFileSize (base64Str: string, MAX_WIDTH = 400, MAX_HEIGHT = 500) {
        const resized_base64: string = await new Promise((resolve) => {
            const img = new Image()
            img.src = base64Str
            img.onload = () => {
                const canvas = document.createElement('canvas')
                let width = img.width
                let height = img.height

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width
                        width = MAX_WIDTH
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height
                        height = MAX_HEIGHT
                    }
                }
                canvas.width = width
                canvas.height = height
                const ctx = canvas.getContext('2d')
                if (!ctx) {
                    return base64Str
                }
                ctx.drawImage(img, 0, 0, width, height)
                resolve(canvas.toDataURL('image/jpeg', 0.1)) // this will return base64 image results after resize
            }
        })
        return resized_base64
    }
}
